<template>
  <v-container
    class="pa-0"
    fluid
    fill-height>
     <v-carousel
        height="auto"
        cycle
        hide-delimiter-background
        show-arrows-on-hover
        eager
      >
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          eager
        >
          <v-img :src="item.src" height="100%" eager/>
        </v-carousel-item>
      </v-carousel>
      <!-- <v-card dark
        color="#000000"
      > -->
      <!-- <div color="#E90587"> -->
        <span v-show="MarqueeShow">
          <marquee-text v-show="MarqueeShow" :duration="marqueeDuration" :repeat="10" :paused="marqueePaused">
            <span
              @mouseover="marqueePaused = true"
              @mouseleave="marqueePaused = false"
              style="color:#E90587; background-color:white; font-size:14px;">
              {{ marqueeTitle }}
            </span>
            <span style="color:#ffffff; background-color:white;">{{ marqueeTitleSpace }}</span>
            <!-- <div class="Wrapper">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</div> -->
          </marquee-text>
        </span>
        <!-- background-color:#ffffff; -->
      <!-- </div> -->
    <!-- </v-card> -->
  </v-container>
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel'
  import MarqueeText from 'vue-marquee-text-component'
  export default {
    components: {
      MarqueeText,
    },
    name: 'HomeBanner',
    created: function () {
      this.getGlobalBannerMarqueeText()
      this.getGlobalBannerMarqueeDuration()
      this.getGlobalBannerMarqueeShow()
      /* this.getGlobalBannerMarqueeRepeat() */
    },
    data () {
      return {
        marqueeTitleSpace: '........',
        marqueeTitle: '',
        marqueePaused: false,
        marqueeDuration: 15,
        marqueeRepeat: 10,
        MarqueeShow: false,
        items: [
          /* {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/1.1.1600x611-px.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/1.2.1600x611-px.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/1.3.1600x611-px.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/1.4.1600x611-px.jpg'),
          }, */
/*           {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/istockphoto-1369742793-1024x1024-1600x611.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/istockphoto-1316238022-1024x1024 - 1600x608 (2).jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/istockphoto-1217364474-1024x1024-1600x611.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/istockphoto-1372353160-1024x1024-1600x611.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/istockphoto-881942310-1024x1024-1600x611.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/istockphoto-1327060591-1024x1024-1600x611.jpg'),
          }, */
          /* {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/banner1_1600x61.jpg'),
          }, */
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/Banner.gif'),
          },
          /* {
            src: require('@/assets/screenshot.gif'),
          }, */
          /* {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/grey_1.1SME.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/grey_1.1Employee.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/grey_1.2Employee.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/grey_1.2Freelance.jpg'),
          },
          {
            src: require('@/assets/MeeTeeMeeNgern/1600x611px/grey_1.2SME.jpg'),
          }, */
        ],
        carousel: '',
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
      }
    },
    methods: {
      getGlobalBannerMarqueeText () {
        this.axios.post(process.env.VUE_APP_API + '/user/getGlobalBannerMarqueeText', {
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.marqueeTitle = response.data.data
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      getGlobalBannerMarqueeDuration () {
        this.axios.post(process.env.VUE_APP_API + '/user/getGlobalBannerMarqueeDuration', {
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.marqueeDuration = response.data.data
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      getGlobalBannerMarqueeRepeat () {
        this.axios.post(process.env.VUE_APP_API + '/user/getGlobalBannerMarqueeRepeat', {
        })
          .then(response => {
            if (response.data.isSuccess) {
              this.marqueeRepeat = response.data.data
              console.log(this.marqueeRepeat, 'this.marqueeRepeat')
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
      getGlobalBannerMarqueeShow () {
        this.axios.post(process.env.VUE_APP_API + '/user/getGlobalBannerMarqueeShow', {
        })
          .then(response => {
            if (response.data.isSuccess) {
              if (response.data.data === 'Y' || response.data.data === 'y') {
                this.MarqueeShow = true
              } else {
                this.MarqueeShow = false
              }
            } else {
              this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(e => {
            this.$swal({type: 'error', title: e.message})
          })
      },
    },
  }
</script>
<style scoped>
  div.Wrapper *
  {
      color: red;
  }
  marquee{
    font-size: 30px;
    font-weight: 800;
    color: #8ebf42;
    font-family: sans-serif;
  }
</style>
